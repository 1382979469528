<template>
    <b-row>
        <b-col>
            <b-card>
                <b-card-body>
                    <b-row>
                        <b-col cols="12">
                            <!-- basic -->
                            <b-form-group
                                label="Nama Advertiser"
                                label-for="basicInput"
                            >
                                <b-form-input
                                id="basicInput"
                                :value="fullname"
                                disabled
                                />
                            </b-form-group>
                        </b-col>
                        <b-col cols="6">
                            <!-- basic -->
                            <b-form-group
                                label="Nama Campaign"
                                label-for="basicInput"
                            >
                                <v-select
                                    v-model="campaign"
                                    label="title"
                                    :options="optionCampaign"
                                    />
                            </b-form-group>
                        </b-col>
                        <b-col cols="6">
                            <!-- basic -->
                            <b-form-group
                                label="Total Pembayaran"
                                label-for="basicInput"
                            >
                                <b-form-input
                                id="basicInput"
                                placeholder="Total Pembayaran"
                                :value="campaign.cost"
                                readonly
                                />
                            </b-form-group>
                        </b-col>
                        <b-col cols="6">
                            <!-- basic -->
                            <b-form-group
                                label="Total Termin"
                                label-for="basicInput"
                            >
                                <b-form-input
                                id="basicInput"
                                placeholder="Total Termin"
                                v-model="amount"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col cols="6" class="pt-2">
                            Kali Pembayaran
                        </b-col>
                        <b-col cols="6"></b-col>
                        <b-col cols="6">
                            <b-button
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                variant="primary"
                                class="mr-1"
                                @click="ajukanTermin"
                                >
                                Ajukan Termin
                            </b-button>

                            <b-button
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                variant="secondary"
                                :to="{ name: 'termin' }"
                                >
                                Batal
                            </b-button>
                        </b-col>
                    </b-row>
                </b-card-body>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import {
    BRow, BCol, BCard, BCardBody, BFormInput, BFormGroup, BButton
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import Ripple from 'vue-ripple-directive'
import axios from '@axios'

export default {
    name: 'CreateTermin',
    components: {
        BRow, BCol, BCard, BCardBody, BFormInput, BFormGroup, BButton, vSelect
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            campaign: {
                cost: null
            },
            optionCampaign: [],
            amount: 0
        }
    },
    mounted() {
        let _ = this
        _.getDraftCampaign()
    },
    methods: {
        getDraftCampaign() {
            let _ = this
            axios.get('/advertiser/termin/available-campaigns')
                .then(resp => {
                    let campaigns = resp.data.data
                    console.log(campaigns)
                    _.optionCampaign = campaigns.map(cp => {
                        return {
                            title: cp.name,
                            value: cp.id,
                            cost: cp.cost
                        }
                    })

                    if ( _.$route.query.campaignId ) {
                        _.campaign = _.optionCampaign.filter(c => {
                            return c.value == _.$route.query.campaignId
                        })[0]
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        },
        ajukanTermin() {
            let _ = this
            axios.post('/advertiser/termin', { campaignID: _.campaign.value, amount: _.amount, paymentAmount: _.campaign.cost })
                .then(resp => {
                    _.$router.push({name: 'termin', params: { successCreate: true }})
                })
                .catch(err => {
                    console.log(err.response)
                })
        }
    },
    computed: {
        fullname() {
            let _ = this
            return _.$store.state.digirans.firstName + ' ' + _.$store.state.digirans.lastName
        }
    }
}
</script>